// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/imageTextComponent.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/imageTextComponent.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import React from "react";
const ImageTextComponent = ({
  image,
  heading,
  detail,
  component,
  reverse
}) => {
  return <div className={`image-text-container ${reverse && "reverse-flex"}`}>
      <div className="image-side">
        <img src={image} alt="Image" loading="lazy" />
      </div>
      <div className="text-side">
        {heading && <h2 className="heading-text">{heading}</h2>}
        {detail && <p className="detail-text">{detail}</p>}
        <div className="component-container">
          {component ? component : <></>}
        </div>
      </div>
    </div>;
};
_c = ImageTextComponent;
export default ImageTextComponent;
var _c;
$RefreshReg$(_c, "ImageTextComponent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;