// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ctaBtn.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ctaBtn.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import React from "react";
const CTA = ({
  link,
  text,
  borderWhite
}) => {
  return <div className="cta-container">
      <Link to={link} className="cta-btn" style={{
      border: borderWhite ? "2px solid white" : "none"
    }}>
        {text}
      </Link>
    </div>;
};
_c = CTA;
export default CTA;
var _c;
$RefreshReg$(_c, "CTA");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;